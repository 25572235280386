/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  isShowing: false,
  isLoading: false,
  isDone: false,
  invoiceId: '',
  value: '',
  name: '',
  error: ''
});

export default function editInvoiceInfoReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'SHOW_EDIT_INVOICE_INFO_MODAL': {
      return state
        .set('isShowing', true)
        .set('invoiceId', action.payload.invoiceId)
        .set('value', action.payload.value)
        .set('name', action.payload.name);
    }
    case 'HIDE_EDIT_INVOICE_INFO_MODAL': {
      return INITIAL_STATE;
    }
    case 'EDIT_INVOICE_INFO_STARTED': {
      return state.set('isLoading', true);
    }
    case 'EDIT_INVOICE_INFO_DONE': {
      return state.set('isLoading', false).set('isDone', true).set('error', '');
    }
    case 'EDIT_INVOICE_INFO_FAILED': {
      return state.set('isLoading', false).set('error', action.payload.error);
    }
    case 'EDIT_INVOICE_INFO_INPUT_CHANGE': {
      return state.set('value', action.payload.value);
    }
    default: {
      return state;
    }
  }
}
