/** @format */

import request from '@edgeguideab/client-request';
import config from 'config';

export default function editInvoiceInfo() {
  return async (dispatch, getState) => {
    dispatch({ type: 'EDIT_INVOICE_INFO_STARTED' });
    const state = getState();
    const invoiceId = state.modals.getIn(['editInvoiceInfo', 'invoiceId']);
    const value = state.modals.getIn(['editInvoiceInfo', 'value']);
    const name = state.modals.getIn(['editInvoiceInfo', 'name']);

    try {
      await request.post(
        `${config.apiServer}/configurations/edit-invoice-info/${invoiceId}`,
        { body: { value, name } }
      );
    } catch (errorResponse) {
      return dispatch({
        type: 'EDIT_INVOICE_INFO_FAILED',
        payload: {
          error: errorResponse.body.msg || 'errors.server'
        }
      });
    }

    dispatch({
      type: 'EDIT_INVOICE_INFO_DONE',
      payload: { value, name }
    });
  };
}
