/** @format */

import { fromJS } from 'immutable';

const INITIAL_STATE = fromJS({
  loading: false,
  eagleEye: false,
  isGatewayApiAvailable: true,
  error: '',
  configurations: [],
  shares: [],
  selectedShare: {},
  page: 0,
  pageLimit: 10,
  offset: 0,
  total: 0,
  sortedBy: 'createdAt',
  sortOrder: 'descending',
  searchQuery: '',
  showShares: false,
  filter: 'all',
  filterCountry: '',
  oldSearch: ''
});

export default function list(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'FOLDER_SELECTED': {
      return state.set('page', 0).set('offset', 0).set('total', 0);
    }
    case 'CONFIGURATION_CHANGE_PAGE': {
      return state
        .set('page', action.payload)
        .set('offset', (action.payload - 1) * state.get('pageLimit'));
    }
    case 'CONFIGURATION_LIST_INIT_STATE': {
      const {
        page = state.get('page'),
        searchQuery = state.get('searchQuery'),
        pageLimit = state.get('pageLimit'),
        sortOrder = state.get('sortOrder'),
        sortedBy = state.get('sortedBy'),
        offset = state.get('offset'),
        filterCountry = state.get('filterCountry')
      } = action.payload;

      return INITIAL_STATE.merge(
        fromJS({
          page,
          searchQuery,
          pageLimit,
          sortOrder,
          sortedBy,
          offset,
          filterCountry
        })
      );
    }
    case 'CONFIGURATION_LIST_SORT': {
      const column = action.payload.column;
      const order = action.payload.order;
      return state.set('sortedBy', column).set('sortOrder', order);
    }
    case 'CONFIGURATIONS_LOAD_START': {
      if (!action.payload.append) {
        state = state.set('configurations', fromJS([]));
      }
      return state
        .set('eagleEye', action.payload.eagleEye)
        .set('error', '')
        .set('loading', true);
    }
    case 'CONFIGURATIONS_LOAD_DONE': {
      let newState = state;
      const newConfigurations = fromJS(action.payload.configurations);
      if (action.payload.append) {
        newState = newState.set(
          'configurations',
          state.get('configurations').push(...newConfigurations)
        );
      } else {
        newState = newState.set('configurations', newConfigurations);
      }
      return newState
        .set('loading', false)
        .set('total', action.payload.total)
        .set('isGatewayApiAvailable', action.payload.isGatewayApiAvailable)
        .set('page', action.payload.page)
        .set('offset', action.payload.offset);
    }
    case 'CONFIGURATIONS_LOAD_FAILED': {
      const msg = action.payload.msg;
      return state.set('loading', false).set('error', msg);
    }
    case 'DELETE_CONFIGURATION_DONE': {
      const deletedConfiguration = state.getIn([
        'deleteConfigurationModal',
        'id'
      ]);

      return state
        .set(
          'configurations',
          state
            .get('configurations')
            .filter(
              (configuration) =>
                configuration.get('id') !== deletedConfiguration
            )
        )
        .set('total', state.get('total') - 1);
    }
    case 'CONFIGURATIONS_SEARCH_BAR_INPUT_CHANGE': {
      return state.set('searchQuery', action.payload);
    }
    case 'CONFIGURATION_LIST_SHOW_SHARES': {
      return state.set('showShares', true);
    }
    case 'CONFIGURATION_LIST_HIDE_SHARES': {
      return state.set('showShares', false);
    }
    case 'SHARES_LOAD_START': {
      return state.set('loading', true);
    }
    case 'SHARES_LOAD_DONE': {
      return state
        .set('loading', false)
        .set('total', action.payload.total)
        .set('offset', action.payload.offset)
        .set('page', action.payload.page)
        .set('shares', fromJS(action.payload.shares));
    }
    case 'SHARES_LOAD_FAILED': {
      return state.set('loading', false).set('error', action.payload.msg);
    }
    case 'EDIT_SHARE_DONE': {
      return state.update('shares', (shares) =>
        shares.map((share) =>
          share.get('id') === action.payload.get('id') ? action.payload : share
        )
      );
    }
    case 'REMOVE_SHARE_DONE': {
      return state.update('shares', (shares) =>
        shares.filter((share) => share.get('id') !== action.payload.get('id'))
      );
    }
    case 'CLEAR_SEARCH': {
      return state
        .set('oldSearch', state.get('searchQuery'))
        .set('searchQuery', '');
    }
    case 'RECOVER_OLD_SEARCH': {
      return state
        .set('searchQuery', state.get('oldSearch'))
        .set('oldSearch', '');
    }
    case 'ON_FILTER_CHANGE': {
      return state.set(action.payload.name, action.payload.value);
    }
    default: {
      return state;
    }
  }
}
