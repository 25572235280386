/** @format */

import { FunctionComponent } from 'react';
import styled from 'styled-components';

const Box = styled.div`
  width: 50%;
  border: 1px solid black;
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  box-shadow: 0 3px 3px black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > h1 {
    text-align: center;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const PreActivationLandingPage: FunctionComponent = () => {
  return (
    <Box>
      <h3>Welcome to the future!</h3>
      <p>
        This is the future solution for how to activate and configure your Edge
        or IRIS terminal from AddSecure. It will be launched during autumn 2023.
      </p>
      <p>Until then: </p>
      <p>
        If you have bought an <strong>IRIS-4 50</strong>, to activate your
        terminal:{' '}
        <a href='https://www.addsecure.com/activate/'>
          https://www.addsecure.com/activate/
        </a>
      </p>{' '}
      <p>
        If you have bought an <strong>Edge DS</strong>, your terminal is already
        activated and configured.
      </p>
      <p>
        {' '}
        If you need more product information, go to:{' '}
        <a href='https://www.addsecure.se/product/edge-ds/'>
          https://www.addsecure.se/product/edge-ds/
        </a>
      </p>
    </Box>
  );
};
