/** @format */

import React, { lazy } from 'react';
import { Route } from 'react-router';
import urls from 'utils/urls';
import { PreActivationLandingPage } from 'components/views/Configuration/PreActivationLandingPage/View';

const ConfigurationsListContainer = lazy(() =>
  import('components/views/Configuration/List/ConfigurationsListContainer')
);
const ConfigurationDetailsContainer = lazy(() =>
  import(
    'components/views/Configuration/View/Details/ConfigurationDetailsContainer'
  )
);
const SecurityRouterConfigurationDetailsContainer = lazy(() =>
  import(
    'components/views/Configuration/View/Details/SecurityRouter/SecurityRouterConfigurationDetailsContainer'
  )
);
const SubscriptionDetailsContainer = lazy(() =>
  import(
    'components/views/Configuration/View/SubscriptionDetails/SubscriptionDetailsContainer'
  )
);
const DalmStatusContainer = lazy(() =>
  import('components/views/Configuration/View/DalmStatus/DalmStatusContainer')
);
const SecurityRouterStatusContainer = lazy(() =>
  import(
    'components/views/Configuration/View/SecurityRouterStatus/StatusContainer'
  )
);
const DalmStatusList = lazy(() =>
  import('components/views/Configuration/View/DalmStatusListContainer')
);
const EventsLogContainer = lazy(() =>
  import('components/views/Configuration/View/EventsLogList')
);
const DalmConfigurationEditContainer = lazy(() =>
  import(
    'components/views/Configuration/Edit/Dalm/DalmConfigurationEditContainer'
  )
);
const SecurityRouterConfigurationEditContainer = lazy(() =>
  import(
    'components/views/Configuration/Edit/SecurityRouter/SecurityRouterConfigurationEditContainer'
  )
);
const SubscriptionConfigurationEditContainer = lazy(() =>
  import(
    'components/views/Configuration/Edit/Subscription/SubscriptionConfigurationEditContainer'
  )
);
const ConfigurationProgramContainer = lazy(() =>
  import('components/views/Configuration/Program/ConfigurationProgramContainer')
);
const ConfigurationCommissionContainer = lazy(() =>
  import(
    'components/views/Configuration/Commission/ConfigurationCommissionContainer'
  )
);
const ResetHardwareContainer = lazy(() =>
  import('components/views/Configuration/ResetHardware/ResetHardwareContainer')
);
const GetDalmStatusContainer = lazy(() =>
  import('components/views/Configuration/GetDalmStatus/GetDalmStatusContainer')
);
const GetSecurityRouterStatusContainer = lazy(() =>
  import(
    'components/views/Configuration/GetSecurityRouterStatus/GetStatusContainer'
  )
);
const GetDalmLogContainer = lazy(() =>
  import('components/views/Configuration/GetDalmLog/GetDalmLogContainer')
);
const GetDalmDebugLogContainer = lazy(() =>
  import(
    'components/views/Configuration/GetDalmDebugLog/GetDalmDebugLogContainer'
  )
);
const ViewDalmLogContainer = lazy(() =>
  import('components/views/Configuration/ViewDalmLog/ViewDalmLogContainer')
);
const RebootDalmContainer = lazy(() =>
  import('components/views/Configuration/RebootDalm/RebootDalmContainer')
);
const UpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/FirmwareUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const SecurityRouterUpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/SecurityRouterUpgrades/FirmwareUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const MobileModemUpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/MobileModemFirmwareUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const SecurityRouterMobileModemUpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/SecurityRouterUpgrades/MobileModemFirmwareUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const KernelUpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/KernelUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const SecurityRouterKernelUpgradeProgressContainer = lazy(() =>
  import(
    'components/views/Configuration/SecurityRouterUpgrades/KernelUpgrade/UpgradeProgress/UpgradeProgressContainer'
  )
);
const AlarmTestContainer = lazy(() =>
  import('components/views/Configuration/AlarmTest/Container')
);
const ActivateSubscriptionContainer = lazy(() =>
  import(
    'components/views/Configuration/ActivateSubscription/ActivateContainer'
  )
);

export default function ConfigurationRoutes(withSession) {
  return [
    <Route
      exact
      key={urls.configurationsList}
      path={urls.configurationsList}
      component={withSession(ConfigurationsListContainer)}
    />,
    <Route
      exact
      key={urls.organizationConfigurationsList}
      path={urls.organizationConfigurationsList}
      component={withSession(ConfigurationsListContainer)}
    />,
    <Route
      exact
      key={urls.configurationLog}
      path={urls.configurationLog}
      component={withSession(EventsLogContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterLog}
      path={urls.securityRouterLog}
      component={withSession(EventsLogContainer)}
    />,
    <Route
      exact
      key={urls.configurationAllDalmStatus}
      path={urls.configurationAllDalmStatus}
      component={withSession(DalmStatusList)}
    />,
    <Route
      exact
      key={urls.configurationDalmStatus}
      path={urls.configurationDalmStatus}
      component={withSession(DalmStatusContainer)}
    />,
    <Route
      exact
      key={urls.configurationSecurityRouterStatus}
      path={urls.configurationSecurityRouterStatus}
      component={withSession(SecurityRouterStatusContainer)}
    />,
    <Route
      exact
      key={urls.newDalmConfiguration}
      path={urls.newDalmConfiguration}
      component={withSession(DalmConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.newSecurityRouterConfiguration}
      path={urls.newSecurityRouterConfiguration}
      component={withSession(SecurityRouterConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.resetHardware}
      path={urls.resetHardware}
      component={withSession(ResetHardwareContainer)}
    />,
    <Route
      exact
      key={urls.resetSecurityHardware}
      path={urls.resetSecurityHardware}
      component={withSession(ResetHardwareContainer)}
    />,
    <Route
      exact
      key={urls.configurationDetails}
      path={urls.configurationDetails}
      component={withSession(ConfigurationDetailsContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterConfigurationDetails}
      path={urls.securityRouterConfigurationDetails}
      component={withSession(SecurityRouterConfigurationDetailsContainer)}
    />,
    <Route
      exact
      key={urls.subscriptionConfigurationDetails}
      path={urls.subscriptionConfigurationDetails}
      component={withSession(SubscriptionDetailsContainer)}
    />,
    <Route
      exact
      key={urls.dalmConfigurationEdit}
      path={urls.dalmConfigurationEdit}
      component={withSession(DalmConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterConfigurationEdit}
      path={urls.securityRouterConfigurationEdit}
      component={withSession(SecurityRouterConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterConfigurationEditService}
      path={urls.securityRouterConfigurationEditService}
      component={withSession(SecurityRouterConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.subscriptionConfigurationEdit}
      path={urls.subscriptionConfigurationEdit}
      component={withSession(SubscriptionConfigurationEditContainer)}
    />,
    <Route
      exact
      key={urls.dalmConfigurationEditReadOnly}
      path={urls.dalmConfigurationEditReadOnly}
      component={withSession(DalmConfigurationEditContainer)}
    />,
    <Route
      key={urls.getDalmStatus}
      path={urls.getDalmStatus}
      component={withSession(GetDalmStatusContainer)}
    />,
    <Route
      exact
      key={urls.getSecurityRouterStatus}
      path={urls.getSecurityRouterStatus}
      component={withSession(GetSecurityRouterStatusContainer)}
    />,
    <Route
      exact
      key={urls.getDalmLog}
      path={urls.getDalmLog}
      component={withSession(GetDalmLogContainer)}
    />,
    <Route
      exact
      key={urls.getSecurityRouterLog}
      path={urls.getSecurityRouterLog}
      component={withSession(GetDalmLogContainer)}
    />,
    <Route
      exact
      key={urls.getDalmDebugLog}
      path={urls.getDalmDebugLog}
      component={withSession(GetDalmDebugLogContainer)}
    />,
    <Route
      exact
      key={urls.getSecurityRouterDebugLog}
      path={urls.getSecurityRouterDebugLog}
      component={withSession(GetDalmDebugLogContainer)}
    />,
    <Route
      exact
      key={urls.viewDalmLog}
      path={urls.viewDalmLog}
      component={withSession(ViewDalmLogContainer)}
    />,
    <Route
      exact
      key={urls.viewSecurityRouterLog}
      path={urls.viewSecurityRouterLog}
      component={withSession(ViewDalmLogContainer)}
    />,
    <Route
      exact
      key={urls.configurationProgram}
      path={urls.configurationProgram}
      component={withSession(ConfigurationProgramContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterProgram}
      path={urls.securityRouterProgram}
      component={withSession(ConfigurationProgramContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterCommission}
      path={urls.securityRouterCommission}
      component={withSession(ConfigurationCommissionContainer)}
    />,
    <Route
      exact
      key={urls.configurationCommission}
      path={urls.configurationCommission}
      component={withSession(ConfigurationCommissionContainer)}
    />,
    <Route
      exact
      key={urls.rebootDalm}
      path={urls.rebootDalm}
      component={withSession(RebootDalmContainer)}
    />,
    <Route
      exact
      key={urls.firmwareUpgrade}
      path={urls.firmwareUpgrade}
      component={withSession(UpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterFirmwareUpgrade}
      path={urls.securityRouterFirmwareUpgrade}
      component={withSession(SecurityRouterUpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.mobileModemFirmwareUpgrade}
      path={urls.mobileModemFirmwareUpgrade}
      component={withSession(MobileModemUpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterMobileModemFirmwareUpgrade}
      path={urls.securityRouterMobileModemFirmwareUpgrade}
      component={withSession(SecurityRouterMobileModemUpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.kernelUpgrade}
      path={urls.kernelUpgrade}
      component={withSession(KernelUpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.securityRouterKernelUpgrade}
      path={urls.securityRouterKernelUpgrade}
      component={withSession(SecurityRouterKernelUpgradeProgressContainer)}
    />,
    <Route
      exact
      key={urls.alarmTest}
      path={urls.alarmTest}
      component={withSession(AlarmTestContainer)}
    />,
    <Route
      exact
      key={urls.preActivationLandingPage}
      path={urls.preActivationLandingPage}
      component={PreActivationLandingPage}
    />,
    <Route
      exact
      key={urls.preActivationLandingPageCode}
      path={urls.preActivationLandingPageCode}
      component={PreActivationLandingPage}
    />,
    <Route
      exact
      key={urls.subscriptionActivate}
      path={urls.subscriptionActivate}
      component={withSession(ActivateSubscriptionContainer)}
    />,
    <Route
      exact
      key={urls.subscriptionLog}
      path={urls.subscriptionLog}
      component={withSession(EventsLogContainer)}
    />
  ];
}
