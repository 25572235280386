/** @format */

import request from '@edgeguideab/client-request';
import config from 'config.json';
import history from 'utils/history';
import urls from 'utils/urls';
import loginRedirect from './loginRedirect';
import languageActions from 'actions/language';
import { showRememberMeBox } from 'config';

export default function acceptPolicy({ location }) {
  return async (dispatch, getState) => {
    const state = getState();
    const userId = state.modals.getIn(['policy', 'userId']);
    const policyVersion = state.modals.getIn(['policy', 'version']);
    const remember = state.session.getIn(['loginForm', 'remember']);
    dispatch({
      type: 'SESSION_LOGIN_STARTED'
    });
    dispatch({
      type: 'HIDE_POLICY_MODAL'
    });
    let response;
    try {
      response = await request.post(
        `${config.apiServer}/sessions/acceptPolicy`,
        {
          body: { userId, remember, policyVersion }
        }
      );
    } catch (errorResponse) {
      window.localStorage.removeItem('encryptedUsername');
      window.localStorage.removeItem('encryptedPassword');
      return dispatch({
        type: 'SESSION_LOGIN_FAILED',
        payload: {
          msg: errorResponse.body.msg || 'errors.maintenance',
          resetCaptcha: errorResponse.body.resetCaptcha
        }
      });
    }

    if (remember && showRememberMeBox) {
      window.localStorage.setItem(
        'encryptedUsername',
        response.body.encryptedUsername
      );
      window.localStorage.setItem(
        'encryptedPassword',
        response.body.encryptedPassword
      );
    }

    dispatch({
      type: 'SESSION_LOGIN_DONE',
      payload: {
        msg: response.body.msg,
        user: response.body.user,
        loggedIn: !response.body['2FA'],
        products: response.body.products,
        supportedEeprom: response.body.supportedEeprom
      }
    });
    const hasStartPage = state.global.get('ewmFeatures').includes('startPage');
    if (!response.body['2FA']) {
      window.localStorage.setItem('csrfToken', response.body.csrfToken);
      await dispatch(
        languageActions.changeLanguage(response.body.user.lastLanguage)
      );
      let path = null;
      if (location) path = location.pathname;
      loginRedirect({
        hasStartPageAccess: response.body.user.acl.startPage.read.length > 0,
        hasStartPage,
        path
      });
      dispatch({ type: 'RESET_LOCATION' });
    } else {
      dispatch({ type: 'SESSION_TWO_FACTOR_VIEW' });
      history.push(urls.loginOTC);
    }
  };
}
